<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Pago anticipado - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col md="6">
                  <b-form-group>
                    <label>Prestamo: </label>
                    <v-select disabled placeholder="Seleccione un prestamo" class="w-100" :filterable="false" label="full_name" v-model="ploan" @search="SearchLoans" :options="loans"></v-select>
                    <small v-if="errors.id_loan" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="N° Pago Anticipado:">
                    <b-form-input type="text" class="text-center" disabled v-model="prepaid.code"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese una numero</small>
                  </b-form-group>
                </b-col>

                 <b-col md="2">
                  <b-form-group label="Medio de Pago:">
                    <b-form-select disabled v-model="prepaid.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                 <b-col md="2">
                  <b-form-group label="Saldo Capital:">
                    <b-form-input type="number"  disabled class="text-right" step="any" v-model="prepaid.principal_balance"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Dias Trans.:">
                    <b-form-input type="number" disabled class="text-center"  v-model="prepaid.days_passed"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Intéres:">
                    <b-form-input type="number" disabled @change="CalculateAmount" class="text-right" step="any" v-model="prepaid.delinquent_amount"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Total:">
                    <b-form-input type="number" disabled class="text-right" step="any" v-model="prepaid.balance_total"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto Cancelado:">
                    <b-form-input disabled type="number" @change="CalculateAmount" class="text-right" step="any" v-model="prepaid.total"></b-form-input>
                    <small v-if="errors.total_prepaid"  class="form-text text-danger" >Ingrese una monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Doc. Adjuntos:">
                     <b-form-file
                      accept="application/pdf"
                      v-model="file"
                      disabled
                      :state="Boolean(file)"
                      @change="onFileChange"
                      placeholder="Selecciona un archivo"
                      drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                    <small v-if="errors.file"  class="form-text text-danger" >Seleccione una archivo</small>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Observaciones:">
                    <b-form-textarea disabled rows="2" v-model="prepaid.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row>

                <b-col md="12">
                <hr>
                </b-col>
                <b-col md="12" class="mb-2">
                  <strong>Prestamo Amortizado</strong> 
                </b-col>

               <b-col md="2">
                  <b-form-group label="N° Prestamo:">
                    <b-form-input type="text" class="text-center" disabled v-model="loan.number"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese una numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input type="number" disabled class="text-right" step="any" v-model="loan.amount"></b-form-input>
                    <small v-if="errors.amount"  class="form-text text-danger" >Ingrese una monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="N° de Cuotas :">
                    <b-form-select disabled v-model="loan.dues" :options="dues"></b-form-select>
                    <small v-if="errors.dues" class="form-text text-danger" >Seleccione una cuota</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Desembolso:">
                    <b-form-input disabled type="date" v-model="loan.disbursement_date"></b-form-input>
                    <small v-if="errors.disbursement_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Pago:">
                    <b-form-input disabled type="date" :min="loan.disbursement_date" v-model="loan.payment_date"></b-form-input>
                    <small v-if="errors.payment_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button type="button" :disabled="prepaid.id_loan == 0" @click="OpenFileLoan" class="form-control" variant="success" ><i class="fas fa-print"></i> Ver Prestamo</b-button>
                  </b-form-group>
                </b-col>


              </b-row>
              <b-row v-if="prepaid.id_new_loan != 0">

                <b-col md="12">
                <hr>
                </b-col>
                <b-col md="12" class="mb-2">
                  <strong>Nuevo Prestamo</strong> 
                </b-col>

               <b-col md="2">
                  <b-form-group label="N° Prestamo:">
                    <b-form-input type="text" class="text-center" disabled v-model="new_loan.number"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese una numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input type="number" disabled class="text-right" step="any" v-model="new_loan.amount"></b-form-input>
                    <small v-if="errors.amount"  class="form-text text-danger" >Ingrese una monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="N° de Cuotas :">
                    <b-form-input disabled v-model="new_loan.dues" type="number"></b-form-input>
                    <small v-if="errors.dues" class="form-text text-danger" >Seleccione una cuota</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Desembolso:">
                    <b-form-input disabled type="date" v-model="new_loan.disbursement_date"></b-form-input>
                    <small v-if="errors.disbursement_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Pago:">
                    <b-form-input disabled type="date" :min="new_loan.disbursement_date" v-model="new_loan.payment_date"></b-form-input>
                    <small v-if="errors.payment_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button type="button" :disabled="prepaid.id_new_loan == 0" @click="OpenFileNewLoan" class="form-control" variant="success" ><i class="fas fa-print"></i> Ver Prestamo</b-button>
                    
                  </b-form-group>
                </b-col>


              </b-row>
              <b-row>

                <b-col md="3"></b-col>
                 <b-col md="2">
                  <b-button type="button" @click="DataPrint" class="form-control" variant="warning" ><i class="fas fa-print"></i> Imprimir Ticket</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="button" :disabled="prepaid.file.length == 0" @click="OpenFile" class="form-control" variant="warning" ><i class="fas fa-print"></i> Ver PDF</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ['id_prepaid'],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Prepaid',
      role: 3,
      prepaid: {
          id_prepaid : '',
          id_loan: '',
          id_user:'',
          id_new_loan: '',
          code:'',
          payment_date:moment(new Date()).local().format("YYYY-MM-DD"),
          payment_method:'008',
          observation: '',
          file: '',
          principal_balance: '',
          days_passed: '',
          delinquent_amount: '',
          balance_total: '',
          total: '',
          state:'1',
      },
      new_loan: {
          id_loan: '',
          id_partner: '',
          id_user_create: '',
          id_user_validate: '',
          id_financial_data: '',
          number: '',
          amount: '0.00',
          dues: '',
          disbursement_date: moment(new Date()).local().format("YYYY-MM-DD"),
          days_of_grace: 0,
          payment_date: moment(new Date()).local().format("YYYY-MM-DD"),
          interest_total: '0.00',
          deferred_interest: '0.00',
          total: '0.00',
          final_installment: '0.00',
          principal_balance: '0.00',
          file: '',
          file_change:'',
          observation: '',
          guarantor1: '',
          guarantor2: '',
          id_check: '',
          state: '1',
          loan_detail:[],
      },
      loan: {
          id_loan: '',
          id_partner: '',
          id_user_create: '',
          id_user_validate: '',
          id_financial_data: '',
          number: '',
          amount: '0.00',
          dues: '',
          disbursement_date: moment(new Date()).local().format("YYYY-MM-DD"),
          days_of_grace: 0,
          payment_date: moment(new Date()).local().format("YYYY-MM-DD"),
          interest_total: '0.00',
          deferred_interest: '0.00',
          total: '0.00',
          final_installment: '0.00',
          principal_balance: '0.00',
          file: '',
          file_change:'',
          observation: '',
          guarantor1: '',
          guarantor2: '',
          id_check: '',
          state: '1',
          loan_detail:[],
      },
      loans: [],
      ploan:null,

      guarantors1: [],
      guarantor1:null,
      guarantors2: [],
      guarantor2:null,

       payment_method: [
        {value :"008", text :'EFECTIVO'},
        {value :"007", text :'CHEQUE'},
        {value :"001", text :'DEPÓSITO EN CUENTA'},
        {value :"003", text :'TRANSFERENCIA DE FONDOS'},
      ],

      file:null,
      financial_data:[],
      dues:[
        {value:'',text:'Seleccion una cuota'},
        {value:'6',text:'6 Meses'},
        {value:'12',text:'12 Meses'},
        {value:'18',text:'18 Meses'},
        {value:'24',text:'24 Meses'},
        {value:'30',text:'30 Meses'},
        {value:'36',text:'36 Meses'},
        {value:'42',text:'42 Meses'},
        {value:'48',text:'48 Meses'},
        {value:'54',text:'54 Meses'},
        {value:'60',text:'60 Meses'},
        {value:'66',text:'66 Meses'},
        {value:'72',text:'72 Meses'},
        {value:'78',text:'78 Meses'},
        {value:'84',text:'85 Meses'},
        {value:'90',text:'90 Meses'},

      ],

      //errors
      errors: {
        id_loan :false,
        total_prepaid: false,
        id_partner: false,
        id_financial_data: false,
        number: false,
        amount: false,
        dues: false,
        disbursement_date: false,
        payment_date: false,
        interest_total: false,
        deferred_interest: false,
        total: false,
        final_installment: false,
        principal_balance: false,
        file: false,
        guarantor1: false,
        guarantor2: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.GetCorrelativeLoan();
    this.GetCorrelativePrepaid();
    this.ListFinancialData();
  },
  methods: {
    GetDataLoan,
    CalculateAmount,



    ListFinancialData,
    GetCorrelativeLoan,
    GetCorrelativePrepaid,
    SearchLoans,

    CalculateLoan,
    DayOfGrace,

    EditPrepaid,
    Validate,
    onFileChange,
    OpenFile,
    OpenFileLoan,
    OpenFileNewLoan,

    DataPrint,
    Print,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};


function OpenFileLoan() {
  let route = this.$router.resolve({
    name: "LoanView",
    params: { id_loan: je.encrypt(this.prepaid.id_loan) },
  });

  window.open(route.href,'_blank');
}

function OpenFileNewLoan() {
  let route = this.$router.resolve({
    name: "LoanView",
    params: { id_loan: je.encrypt(this.prepaid.id_new_loan) },
  });

  window.open(route.href,'_blank');
}

function OpenFile() {
  let url = this.url_base + this.prepaid.file;
  window.open(url,'_blank');
}


function GetDataLoan() {

  if (this.ploan == null) {
    this.prepaid.total = '0.00';
    this.loan.amount = '0.00';
    return false;
  }

  this.prepaid.total = '0.00';
  this.loan.amount = this.ploan.principal_balance;


}

function CalculateAmount() {
  if (this.ploan == null) {
    this.prepaid.total = '0.00';
    this.loan.amount = '0.00';
    return false;
  }

  if (parseFloat(this.ploan.principal_balance) < parseFloat(this.prepaid.total)) {
    this.prepaid.total = '0.00';
  }


  this.loan.amount = parseFloat(this.ploan.principal_balance) - parseFloat(this.prepaid.total)

  this.prepaid.total = parseFloat(this.prepaid.total).toFixed(2);
  this.loan.amount = parseFloat(this.loan.amount).toFixed(2);

  this.CalculateLoan();
}

function DayOfGrace() {
  var date1 = moment(this.loan.disbursement_date);
  var date2 = moment(this.loan.payment_date);
  this.loan.days_of_grace = date2.diff(date1, 'days');

  if (parseFloat(this.loan.days_of_grace) < 0) {
    this.loan.payment_date = this.loan.disbursement_date;
    this.DayOfGrace();
  }

  this.CalculateLoan();
}

function ListFinancialData() {
  let me = this;
  let url = me.url_base + "financial-data/list-actives";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.financial_data = [];
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.financial_data.push({
          value:element.id_financial_data, text:element.tea
        });

        if (element.default == 1) {
          me.loan.id_financial_data = element.id_financial_data;
        }
      }
    }else{
      me.financial_data = [];
      me.loan.id_financial_data = '';
    }
  })
}

function SearchLoans(search, loading) {
  
   let me = this;
    let url = this.url_base + "prepaid/list-prepaid/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
      }).then(function (response) {
            me.loans = response.data;
            loading(false);
      })
    }
}


function GetCorrelativeLoan() {
  let me = this;
  let id_prepaid = je.decrypt(this.id_prepaid)
   let url = me.url_base + "prepaid/view/"+id_prepaid;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
          me.prepaid.id_prepaid = response.data.result.prepaid.id_prepaid; 
          me.prepaid.id_loan = response.data.result.prepaid.id_loan; 
          me.prepaid.id_user = response.data.result.prepaid.id_user; 
          me.prepaid.id_new_loan = response.data.result.prepaid.id_new_loan; 
          me.prepaid.code = response.data.result.prepaid.code; 
          me.prepaid.payment_method = response.data.result.prepaid.payment_method; 
          me.prepaid.payment_date = response.data.result.prepaid.payment_date; 
          me.prepaid.observation = response.data.result.prepaid.observation; 
          me.prepaid.file = response.data.result.prepaid.file; 
          me.prepaid.principal_balance = response.data.result.prepaid.principal_balance; 
          me.prepaid.days_passed = response.data.result.prepaid.days_passed; 
          me.prepaid.delinquent_amount = response.data.result.prepaid.delinquent_amount; 
          me.prepaid.balance_total = response.data.result.prepaid.balance_total; 
          me.prepaid.total = response.data.result.prepaid.total; 
          me.prepaid.state = response.data.result.prepaid.state; 

          me.loan.number = response.data.result.loan.number; 
          me.loan.amount = response.data.result.loan.amount; 
          me.loan.dues = response.data.result.loan.dues; 
          me.loan.disbursement_date = response.data.result.loan.disbursement_date; 
          me.loan.payment_date = response.data.result.loan.payment_date; 
          me.loan.interest_total = response.data.result.loan.interest_total; 
          me.loan.total = response.data.result.loan.total;
          me.loan.final_installment = response.data.result.loan.final_installment;
          me.loan.principal_balance = response.data.result.loan.principal_balance;

          me.new_loan.number = response.data.result.new_loan.number; 
          me.new_loan.amount = response.data.result.new_loan.amount; 
          me.new_loan.dues = response.data.result.new_loan.dues; 
          me.new_loan.disbursement_date = response.data.result.new_loan.disbursement_date; 
          me.new_loan.payment_date = response.data.result.new_loan.payment_date; 
          me.new_loan.interest_total = response.data.result.new_loan.interest_total; 
          me.new_loan.total = response.data.result.new_loan.total;
          me.new_loan.final_installment = response.data.result.new_loan.final_installment;
          me.new_loan.principal_balance = response.data.result.new_loan.principal_balance;

          me.file = null;
          me.ploan = {id: response.data.result.prepaid.id_loan, full_name:response.data.result.prepaid.name+" - "+response.data.result.prepaid.document_number};         
    }else{
      
    }
  })
}

function GetCorrelativePrepaid() {
  let me = this;
  let url = me.url_base + "get-correlative/Prepaid";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.prepaid.code = response.data.result.number;
    }else{
      me.prepaid.code = '';
    }
  })
}

function onFileChange(e) {
  this.prepaid.file_change = e.target.files[0];
}


function CalculateLoan() {

  this.errors.amount = this.loan.amount.length == 0 || parseFloat(this.loan.amount) == 0 ? true : false;
  this.errors.dues = this.loan.dues.length == 0 ? true : false;
  this.errors.disbursement_date = this.loan.disbursement_date.length == 0 ? true : false;
  this.errors.payment_date = this.loan.payment_date.length == 0 ? true : false;

  if (this.errors.amount) {  return false;}else{ this.validate_add = false; }
  if (this.errors.dues) {  return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) {  return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) {  return false;}else{ this.validate_add = false; }

  let me = this;
  let url = me.url_base + "loans/calculate-loan";
  let data = me.loan;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.loan.loan_detail = response.data.result.loan_detail;
        me.loan.interest_total = response.data.result.loan_data.interest_total;
        me.loan.final_installment = response.data.result.loan_data.final_installment;
        me.loan.total = response.data.result.loan_data.total;
        me.loan.principal_balance = response.data.result.loan_data.principal_balance;

        // Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}



function EditPrepaid() {
  let me = this;
  let data = new FormData();
  
  data.append("id_prepaid", this.prepaid.id_prepaid);
  data.append("payment_method", this.prepaid.payment_method);
  data.append("observation", this.prepaid.observation);
  data.append("file", this.prepaid.file_change);
  let url = me.url_base + "prepaid/edit";

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
   
          me.prepaid.file = response.data.result.file;
          me.prepaid.file_change = '';
          me.file = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el pago anticipado ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditPrepaid();
    }
  });
}

function DataPrint() {
  let me = this;
  let id_prepaid = je.decrypt(this.id_prepaid);
  let url = me.url_base + "prepaid/data-print/"+id_prepaid;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.Print(response.data.result)
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = true;
  });
}


function Print(info) {
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("info",JSON.stringify(info)); 

  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}
</script>
